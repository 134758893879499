import Vue from "vue";
import VueRouter from "vue-router";
// import NProgress from "nprogress";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/cyScreen",
    name: "cyScreen",
    component: () => import("../views/cyScreen.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home.vue"),
    redirect: "/beeField",
    children: [
      {
        path: "/beeField",
        name: "beeField",
        component: () => import("../views/beeField.vue"),
      },
      {
        path: "/nectarSource",
        name: "nectarSource",
        component: () => import("../views/nectarSource.vue"),
      },
      {
        path: "/guide",
        name: "guide",
        component: () => import("../views/guide.vue"),
      },
      {
        path: "/beeDetail",
        name: "beeDetail",
        component: () => import("../views/beeDetail.vue"),
      },
      {
        path: "/product",
        name: "product",
        component: () => import("../views/product.vue"),
      },
      {
        path: "/financialHoneyWarehouse",//金融蜜仓
        name: "financialHoneyWarehouse",
        component: () => import("../views/financialHoneyWarehouse.vue"),
      },
      {
        path: "/fenMnitorDetail",
        name: "fenMnitorDetail",
        component: () => import("../views/fenMnitorDetail.vue"),
        children: [
          {
            path: "/dahua",
            name: "dahua",
            component: () => import("../views/dahua.vue"),
          },
          {
            path: "/yingshi",
            name: "yingshi",
            component: () => import("../views/yingshi.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// // 路由守卫
// router.beforeEach((to, from, next) => {
//   // 开始进度条
//   NProgress.start();
//   next();
// });
// router.afterEach(() => {
//   // 完成进度条
//   NProgress.done();
// });

export default router;
