import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: null,
  },
  getters: {},
  mutations: {
    loginType(state, data) {
      state.isLogin = data;
    },
  },
  actions: {
    setLoginType({ state, commit, dispatch }, args) {
      commit("loginType", args);
    },
  },
  modules: {},
});
