<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    if (this.$router.path !== "/beeField") {
      this.$router.replace("/beeField");
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'digital';
  src: url('./assets/fonts/Digital\ Display\ TFB.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  color: #fff;
  background: #00203d;
}

/* 去除滚动条 */
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.row-item {
  cursor: pointer;
}

/* 自定义 nprogress 颜色 */
/* #nprogress .bar {
  background: red !important;
} */
</style>
